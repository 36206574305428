<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <components
          :is="tab.component"
          :param="popupParam"
          :data.sync="data"
          :attachInfo.sync="attachInfo"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'gov-schedule-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'govScheduleInfo',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'GOV_SCHEDULE_MST',
        taskKey: '',
      },
      data: {
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        govScheduleTypeCd: null,  // 대관업무구분
        plantCd: null,  // 사업장코드
        govScheduleName: '',  // 대관업무명
        govScheduleCycleCd: null,  // 법정 주기
        remarks: '',  // 내용
        useFlag: 'Y',  // 사용여부
        inspType: null,  // 검사-종류
        relatedLawsCd: null,  // 검사-관련법규
        inspTarget: '',  // 검사-대상 시설
        contStatus: null,  // 계약-상태
        contType: null,  // 계약-구분
        contVendor: '',  // 계약-업체
        users: [],
        deleteUsers: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    isOld() {
      return Boolean(this.popupParam.govScheduleMstId)
    },
    tabItems() {
      let items = [
        { name: 'govScheduleInfo', icon: 'info', label: '대관업무 기본정보', component: () => import(`${'./govScheduleInfo.vue'}`) },
      ]
      if (this.isOld) {
        items.push({ name: 'govScheduleHistory', icon: 'construction', label: '대관업무 실시이력', component: () => import(`${'./govScheduleHistory.vue'}`) },)
      }
      return items;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.env.gov.mst.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.detailUrl;
        this.$http.param = {govScheduleMstId: this.popupParam.govScheduleMstId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.govScheduleMstId)
        },);
      }
    },
  }
};
</script>